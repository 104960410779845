<template>
  <div class="program">
    <div class="cover-container">
      <div class="cover">
        <a href="" class="back card-secondary-lable" @click.prevent="goBack"><i class="la la-arrow-right la-2x"></i></a>
        <img :src="author.cover_url" alt="" class="bg-brand"/>
      </div>
    </div>


    <div class="collection">
      <div class="title font-weight-bold h6 my-3 mt-2 px-4" v-if="author?.programs?.data.length">
        البرامج
      </div>

      <div class="vertical-scroll d-flex hide-scrollbar ps-2">
        <div class="" v-for="program in author.programs.data" :key="program.id">
          <ProgramVerticalCard :program="program" />
        </div>
      </div>

    </div>

    <div class="">
      <div class="title font-weight-bold h6 my-3 mt-2 px-4" v-if="author?.programs?.data.length">
        الصوتيات
      </div>
      <div class="d-flex text-sm font-weight-bold h5 mb-4 mt-2 px-4">
        <div class="col mb-2">
          <div class="font-weight-bold mb-2 pt-1">
            العدد الكلي
            <span class="mx-1 text-success font-weight-normal" v-if="author.tracks && author.tracks.data">{{ author.tracks.data.length }}</span>
          </div>
       
        </div>
        <!-- <div class="text-center float-end font-weight-normal">
          <small class="font-weight-bold mb-1 d-block">المدة بالكامل</small>
          <small class="text-success d-block">13:01:33</small>
        </div> -->
        <div>
          <button class="btn btn-success btn-sm font-weight-bold rounded-md" @click="playAllFromStart" v-if="author.tracks && author.tracks.data && author.tracks.data.length">
            <i class="la la-play me-2"></i> تشغيل الكل
          </button>
        </div>
      </div>
      <div class="row mx-0">

        <HorizontalCardLoader v-if="$apollo.queries.author.loading" />

        <div class="col-md-12" v-for="track in author.tracks? author.tracks.data: []" :key="track.id">
          <TrackHorizontalCard :track="track" :tracklist="author.tracks.data" />
        </div>

        <div class="col-md-12 text-center" v-if="!$apollo.queries.author.loading && !author.tracks.data.length">
          <div class="main-card p-5">
            لا توجد صوتيات هنا!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackHorizontalCard from "../../components/TrackHorizontalCard.vue";
import gql from 'graphql-tag'
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';
import ProgramVerticalCard from "../../components/ProgramVerticalCard.vue";

export default {
  name: "Home",
  components: {
    TrackHorizontalCard,
    HorizontalCardLoader,
    ProgramVerticalCard
},

  data: () => {
    return {
      author: {},
    };
  },

  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    goBack() {
      if (this.hasHistory()) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    playAllFromStart() {
      let firstTrack = this.author.tracks.data[0]
      if(this.$store.getters.isLoggedIn) {
        this.$store.commit('SET_TRACKLIST', { 
          tracklist: this.author.tracks.data, 
          index: 0,
          track: firstTrack,
          })
      }
      this.$router.push({ name: 'Track', params: { id: firstTrack.id } })
    },
  },

  apollo: {
    author: {
      query: gql`
        query Provider($id: ID, $first: Int!) {
          author (id: $id) {
            id
            name_ar
            name_en
            thumbnail_url
            cover_url

            programs (first: $first) {
              data {
                id
                name_ar
                name_en
                thumbnail_url
              }
            }

            tracks (first: $first) {
              data {
                id
                name_ar
                name_en
                description_ar
                description_en
                duration_display
                thumbnail_url
                cover_url
                full_url

                authors {
                  id
                  name_ar
                  name_en
                }

                categories {
                  id
                  name_ar
                  name_en
                }
              }
            }
          }
        }
      `,
      variables () {
        return {
          id: this.$route.params.id,
          first: 10000,
        }
      }
    }
  },
};
</script>
